import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { KlippyBase } from './klippy-base';

@Injectable({
  providedIn: 'root'
})
export class KlippyService extends KlippyBase  {
  apiUrl='';
  constructor(private http: HttpClient) { 
    super(http);

    if(environment.apiURL.includes('http')){
      this.apiUrl = environment.apiURL;
    }
    else{
      let locationOrigin = location.origin;    
      this.apiUrl = locationOrigin.replace("admin", "") + environment.apiURL;
    }
    
    console.log("apiURL at service file",this.apiUrl);
  }
  

  getAdminDashboard(startDate, endDate): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}/admin/dashboard/?startDate=${startDate}&endDate=${endDate}`);
  }

  getManageVenueDashboard(startDate, endDate): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}/admin/allvenue/?startDate=${startDate}&endDate=${endDate}`);
  }

  getVenueEdit(venueId, startDate, endDate): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}/admin/venue/?venueId=${venueId}&startDate=${startDate}&endDate=${endDate}`);
    // return this.http.get<any>(`${this.apiUrl}/admin/venue/venue64afd2e5757029`);
  }

  getCancellationReport(venueId, startDate, endDate): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}/csv/cp/?vid=${venueId}&startDate=${startDate}&endDate=${endDate}`);
    //csv/cp?vid=venue64af4ac7d96442&startDate=2024-05-01&endDate=2024-08-31
  }

  createVenue(param: any): Observable<any[]> {
    return this.http.post<any>(`${this.apiUrl}/admin/createVenue/`, param);
  }

  saveVenue(param: any): Observable<any[]> {
    return this.http.post<any>(`${this.apiUrl}/venue/save/`, param);
  }

  getVenueProfile(venueId): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}/admin/getvenue/${venueId}`);
  }

  getAllPromoters(startDate, endDate): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}/admin/promoters/?startDate=${startDate}&endDate=${endDate}`);
  }

  updatePromoter(userId): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}/admin/promoter/${userId}`);
  }

  markfavouriteOrPromote(param: any, isUpdate = 1) {
    let serviceName = "markfavourite";
    if (isUpdate == 1)
      serviceName = "promoter";
    else if (isUpdate == 2)
      serviceName = "unfavourite";
    return this.http.post<any>(`${this.apiUrl}/venue/${serviceName}/`, param);
  }

  getAllUsers(startDate, endDate): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}/admin/allusers/?startDate=${startDate}&endDate=${endDate}`);
  }

  getAllBroadcast(startDate, endDate): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}/admin/broadcasts/?startDate=${startDate}&endDate=${endDate}`);
  }

  getEditBroadcast(venueId, startDate, endDate): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}/admin/broadcast/?venueId=${venueId}&startDate=${startDate}&endDate=${endDate}`);
  }

  getBroadcastData(broadcastId, venueId): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}/broadcast/getbyvenue/?broadcastId=${broadcastId}&venueId=${venueId}`);
  }

  getSegmentData(venueId): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}/broadcast/getsegment/?venueId=${venueId}`);
  }

  postBroadcast(param: any): Observable<any[]> {
    return this.http.post<any>(`${this.apiUrl}/broadcast/saveorcreate/`, param);
  }

  sendBroadcast(param: any): Observable<any[]> {
    return this.http.post<any>(`${this.apiUrl}/broadcast/send/`, param);
  }

  postSegment(param: any): Observable<any[]> {
    return this.http.post<any>(`${this.apiUrl}/broadcast/savesegment/`, param);
  }

  uploadBroadcastImage(param: any, broadcastId, venueId): Observable<any[]> {
    return this.http.post<any>(`${this.apiUrl}/broadcast/upload/?venueId=${venueId}&broadcastId=${broadcastId}`, param);
  }

  uploadVenueGallery(param: any, venueId, imageType): Observable<any[]> {
    return this.http.post<any>(`${this.apiUrl}/admin/venueimage/?imageType=${imageType}&venueId=${venueId}`, param);
  }


  uploadVenueImages(param: any, type, venueId, imageId = null): Observable<any[]> {
    let urlParam = `?imageType=${type}&venueId=${venueId}`;
    if (imageId)
      urlParam += `&imageId=${imageId}`;
    return this.http.post<any>(`${this.apiUrl}/venue/venue-image.php` + urlParam, param);
  }

  removeImage(imageId) {
    return this.http.post<any>(`${this.apiUrl}/venueimage/remove/`, {
      "imageId": imageId
    });

  }

  getVenueContract(venueId): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}/admin/contracts/${venueId}/`);
  }

  postVenueContract(param: any): Observable<any[]> {
    return this.http.post<any>(`${this.apiUrl}/admin/contract/`, param);
  }

  updateVenueContract(param: any): Observable<any[]> {
    // Assuming you need to send PUT request for updating contract
    return this.http.put<any[]>(`${this.apiUrl}/admin/contract/${param.contractId}/`, param);
  }

  getAllSupport(startDate, endDate): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}/helpdesk/getAll/?startDate=${startDate}&endDate=${endDate}`);
  }

  getAllOffers(startDate, endDate): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}/offers/get/?startDate=${startDate}&endDate=${endDate}`);
  }

  updateOffer(param): Observable<any[]> {
    return this.http.post<any>(`${this.apiUrl}/offers/update/`, param);
  }

  createOffer(param): Observable<any[]> {
    return this.http.post<any>(`${this.apiUrl}/offers/create/`, param);
  }

  uploadOfferImage(offerId, param: any): Observable<any[]> {
    return this.http.post<any>(`${this.apiUrl}/offers/upload?offerId=${offerId}`, param);
  }

  // get profile info of guest :
  getGuestInfo(userId): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}/guest/get/${userId}`);
  }

  // post api for upload profile pic of guest:
  uploadVenueUserPic(userId, param: any): Observable<any[]> {
    return this.http.post<any>(`${this.apiUrl}/venueUser/upload?id=${userId}`, param);
  }

  updateVenueUser(param: any): Observable<any[]> {
    return this.http.post<any>(`${this.apiUrl}/venueUser/update`, param);
  }
  createVenueUser(param: any): Observable<any[]> {
    return this.http.post<any>(`${this.apiUrl}/venueUser/create`, param);
  }

  loginUser(email, password): Observable<any[]> {
    return this.http.post<any>(`${this.apiUrl}/login/admin`, { userName: email, passkey: password });
  }

  getAllCountry() {
    return this.http.get<any>(`${this.apiUrl}/admin/countries`,)
  }
  getCitiesByCountry(countryId) {
    return this.http.get<any>(`${this.apiUrl}/admin/cities?countryId=${countryId}`,)
  }

}
